/* @flow */
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../Constants';

export function removeNotification(notification: any) {
  if (!notification) {
    throw new Error('*** no notification.');
  }

  return {
    type: REMOVE_NOTIFICATION,
    notification,
  };
}

/**
 * Create the notification.
 * @param  {Object}
 *
 * @throws {Error} the arguments error.
 */
export function createNotification(data: {message: string; dispatch: Function; timeout?: number; type?: string;}) {
  const {
    message,
    dispatch,
    timeout,
    type,
  } = data;

  const DEFALT_TIME_OUT: number = 5000;

  // TODO: type

  if (!(message && dispatch)) {
    throw new Error(`createNotification> the arguments error: message: ${message}, dispatch: ${JSON.stringify(dispatch)}`);
  }

  const date: any = new Date();
  const key: string = `notification_${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

  const timeoutMilisec: number = timeout || DEFALT_TIME_OUT;

  const notification: any = {
    message,
    key,
    action: 'x',
    dispatch,
  };

  notification.onClick = (n: any, deactivate: Function) => {
    deactivate();
    setTimeout(() => dispatch(removeNotification(data)), timeoutMilisec);
  };

  return notification;
}

export function addNotification(message: string, dispatch: Function, timeout?: number, type?: string) {
  if (!(message && dispatch)) {
    throw new Error(`*** no message: ${message} or dispatch: ${JSON.stringify(dispatch)}.`);
  }

  return {
    type:         ADD_NOTIFICATION,
    notification: createNotification({ message, dispatch, timeout, type }),
    dispatch,
  };
}
