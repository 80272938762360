/* @flow */

import React from 'react';
import ReactDomServer from 'react-dom/server';
import {
  StaticRouter,
  BrowserRouter
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk        from 'redux-thunk';
import {
  createStore,
  applyMiddleware
} from 'redux';
import Loadable from 'react-loadable';
import { getBundles } from 'react-loadable/webpack';
import { CookiesProvider } from 'react-cookie';

import * as GA from '../../vendor/gitlab.com/taiyuf/react-google-analytics/GoogleAnalytics';
import Context from '../../vendor/gitlab.com/taiyuf/js_context/Context';
import rootReducer, { initialState } from './reducers/index';
import App from './containers/App';
import {
  JIN_ARTIST_COM_LANGUAGE,
} from './Constants';

export function configureStore(state: typeInitialState, mode: string = 'production'): any {

  const middlewares: Array<any> = [];
  middlewares.push(thunk);
  if (mode !== 'production') {
    middlewares.push(createLogger());
  }
  // middlewares.push(versionCheck);

  return applyMiddleware(...middlewares)(createStore)(rootReducer, state);
}

function getHashFromCSS(hash: any) {
  const key: string = Object.keys(hash)[0];
  return hash[key].match(/.*-([^-+]+)\.css$/, '$1')[1];
}

function getHashFromJS(hash: any) {
  const key: string = Object.keys(hash)[0];
  return hash[key].match(/.*-([^-+]+)\.bundle\.js$/, '$1')[1];
}

function renderPage(app: string, state: typeInitialState, bundles: Array<any>, jsHash: any, cssHash: any): string {
  const stateStr: string     = JSON.stringify(state);
  const cssHashValue: string = getHashFromCSS(cssHash);
  const jsHashValue: string  = getHashFromJS(jsHash);

  return (`
    <!DOCTYPE html>
    <html lang="ja">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="format-detection" content="telephone=no">
        <meta name="msapplication-TileColor" content="#da532c">
        <meta name="theme-color" content="#ffffff">
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png">
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/es5-shim/4.5.7/es5-shim.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/es5-shim/4.5.7/es5-sham.min.js"></script>
        <script defer src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
        <link rel="manifest" href="/site.webmanifest">
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5">
        <link rel="stylesheet" type="text/css" href="/assets/bundle-${cssHashValue}.css">
        <link rel="stylesheet" type="text/css" href="/assets/main-bundle-${cssHashValue}.css">

        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-104789803-3"></script>
        <script>
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
         gtag('config', 'UA-104789803-3');
        </script>
      </head>
      <body class='body'>
        <section className="section">
          <div className="container">
            <div id="app">${app}</div>
            <script id="initial-data" type="text/plain" data-json='${stateStr}'></script>
            ${bundles.map(bundle => `<script src="/assets/${bundle.file}"></script>`).join('\n')}
            <script src="/assets/main-${jsHashValue}.bundle.js"></script>
            <script src="/assets/vendor-${jsHashValue}.bundle.js"></script>
          </div>
        </section>
      </body>
    </html>
  `);
}

export function createServerApp(req: any, res: any, env: string, config: typeConfigState, hash: any, jsHash: any, cssHash: any, notifications: Array<string>): void {
  const context: any = {};
  const modules = [];
  const store: any   = configureStore(initialState, env);
  const html: string = ReactDomServer.renderToString(
    <CookiesProvider cookies={req.universalCookies}>
      <Provider store={store}>
        <StaticRouter location={req.url} context={context}>
          <Loadable.Capture report={moduleName => modules.push(moduleName)}>
            <App config={config} ui={initialState.ui} />
          </Loadable.Capture>
        </StaticRouter>
      </Provider>
    </CookiesProvider>
  );

  const bundles = getBundles(hash, modules);
  const state   = store.getState();

  state.config.data.google_analytics_id = config.google_analytics_id;
  state.config.data.env                 = env;
  state.initialNotifications            = notifications;
  state.ui.data.language                = req.universalCookies.get(JIN_ARTIST_COM_LANGUAGE) ? req.universalCookies.get(JIN_ARTIST_COM_LANGUAGE) : 'JP';

  if (context.url) {
    res.writeHead(301, {
      Location: context.url
    });
  } else {
    res.write(renderPage(html, state, bundles, jsHash, cssHash));
  }

  res.end();
}

export function createClientApp() {
  // 初期ステートをSSRから受け継ぐ
  const initialDataDiv: any         = document.getElementById('initial-data');
  const stateString: string         = initialDataDiv ? initialDataDiv.getAttribute('data-json') : '{}';
  let state: typeInitialState | any = initialState;

  if (stateString) {
    try {
      state = JSON.parse(stateString);
    } catch (e) {
      console.log(`*** JSON parse error: ${stateString}`);
    }
  }

  // 初期設定
  const config: typeConfigState | any = state.config && state.config.data ? state.config.data : initialState.config;
  const env: string                   = state.config && state.config.data && state.config.data.env ? initialState.config.data.env : 'production';
  const store: any                    = configureStore(state, env);
  const ctx: any                      = new Context();
  const ps: any                       = {
    log: ctx,
    config,
  };

  ps.initialNotifications = state.initialNotifications || [];
  ps.ga = GA.init({
    googleAnalyticsID: config.google_analytics_id,
    env,
  });

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Provider store={store}>
          <App config={config} ui={initialState.ui} {...ps} />
        </Provider>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export function getCookieOptions(): { path: string, expires: any } {
  const dt = new Date();
  dt.setDate(dt.getMonth() + 2); // 2 month

  return {
    path:    '/',
    expires: dt,
  };
}
