/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

import { createClientApp } from './universal';

const app = document.getElementById('app');

if (!app) {
  throw new Error('Not found an "app" element.');
}

Loadable.preloadReady().then(() => ReactDOM.hydrate(createClientApp(), app));
