/* @flow */
import {
  ENABLE_GLOBAL_MENU,
  DISABLE_GLOBAL_MENU,
  SET_SCROLL_POSITION,
  SET_WINDOW_WIDTH,
  SET_NAV_HEIGHT,
  SET_BG_HEIGHT,
  SET_LANGUAGE,
} from '../Constants';

export function enableGlobalMenu(): {type: string} {
  return {
    type: ENABLE_GLOBAL_MENU
  };
}

export function disableGlobalMenu(): {type: string} {
  return {
    type: DISABLE_GLOBAL_MENU
  };
}

export function setScrollPotision(position: number): {type: string, position: number} {
  return {
    type: SET_SCROLL_POSITION,
    position
  };
}

export function setWindowWidth(windowWidth: number) {
  return {
    type: SET_WINDOW_WIDTH,
    windowWidth
  };
}

export function setNavHeight(navHeight: number) {
  return {
    type: SET_NAV_HEIGHT,
    navHeight
  };
}

export function setBgHeight(bgHeight: number) {
  return {
    type: SET_BG_HEIGHT,
    bgHeight
  };
}

export function setLanguage(language: string) {
  return {
    type: SET_LANGUAGE,
    language,
  };
}
