/* @flow */
import React from 'react';
import {
  PropTypes,
  instanceOf,
} from 'prop-types';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import {
  Cookies,
} from 'react-cookie';

import {
  enableGlobalMenu,
  disableGlobalMenu,
  setLanguage,
} from '../actions/ui';

import {
  LANG_NAME_ENGLISH,
  LANG_NAME_JAPANESE,
  LANG_NAME_CHINESE,
  JIN_ARTIST_COM_LANGUAGE,
} from '../Constants';
import {
  getCookieOptions,
} from '../universal';

import SNSIcon from '../components/SNSIcon';

class Navigation extends React.Component<typeAppProps> {
  static propTypes = {
    config:   PropTypes.object.isRequired,
    ui:       PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    ga:       PropTypes.object,
    cookies:  instanceOf(Cookies).isRequired,
  };

  static defaultProps = {
    ga: null,
  };

  constructor(props) {
    super(props);

    this.handleMenu = this.handleMenu.bind(this, 'button');
  }

  handleMenu: Function;

  handleMenu(label) {
    const {
      dispatch,
      ui,
      ga
    } = this.props;

    if (ui.globalMenu === true) {
      dispatch(disableGlobalMenu());
    } else {
      dispatch(enableGlobalMenu());
    }

    const gaEvent: typeGaEventProps = {
      category: 'nav',
      action:   'click',
      label
    };

    if (ga) {
      ga.event(gaEvent);
    }
  }

  render() {
    const {
      ui,
      ga,
      dispatch,
      cookies,
    } = this.props;

    const snsIcons = (size, css) => (
      <React.Fragment>
        <SNSIcon link="https://twitter.com/zinfactory" type="twitter" size={size} cssClass={css} ga={ga} />
        <SNSIcon link="https://instagram.com/zinfactory" type="instagram" size={size} cssClass={css} ga={ga} />
        <SNSIcon link="https://youtu.be/h2UAW_GWwhA" type="youtube" size={size} cssClass={css} ga={ga} />
      </React.Fragment>
    );

    const globalMenu: string = ui.globalMenu ? 'is-active' : '';

    // menu items
    const informationMenu: typeNavigationMenuProps = {
      title: 'Information',
      link:  '#information',
      label: 'information',
    };

    const profileMenu: typeNavigationMenuProps = {
      title: 'Profile',
      link:  '#profile',
      label: 'profile',
    };

    const discographyMenu: typeNavigationMenuProps = {
      title: 'Discography',
      link:  '#discography',
      label: 'discography',
    };

    const menus: Array<typeNavigationMenuProps> = [
      informationMenu,
      profileMenu,
      discographyMenu,
    ];

    const menuItem: Function = ({ menu, index }: typeNavigationItem) => {
      const commonCssClass: string = 'title navbar-item has-text-centered is-size-4 univia-pro-font';
      const commonStyle: { height: string} = { height: '100%' };
      const keyIndex: Function = (n: number): string => `navigationMenu_${n}`;

      const handleMenuDisable = (e: any) => {
        e.preventDefault();
        dispatch(disableGlobalMenu());

        const gaEvent: typeGaEventProps = {
          category: 'nav',
          action:   'click',
          label:    menu.label,
        };

        if (ga) {
          ga.event(gaEvent);
        }
      };

      return (
        <React.Fragment key={keyIndex(index)}>
          <p className={`${commonCssClass} is-hidden-mobile navPCLink`} style={commonStyle}>
            <Link to={menu.link} onClick={handleMenuDisable}>
              {menu.title}
            </Link>
          </p>
          <p className={`${commonCssClass} is-hidden-tablet navMobileLink`} style={commonStyle}>
            <Link to={menu.link} onClick={handleMenuDisable}>
              {menu.title}
            </Link>
          </p>
        </React.Fragment>
      );
    };

    // languages
    const languages: Array<typeNavLanguage> = [
      {
        JP: LANG_NAME_JAPANESE,
      },
      {
        EN: LANG_NAME_ENGLISH,
      },
      {
        CN: LANG_NAME_CHINESE,
      },
    ];

    const handleChangeLang = (langCode: string, e: any) => {
      e.preventDefault();

      cookies.set(JIN_ARTIST_COM_LANGUAGE, langCode, getCookieOptions());
      dispatch(setLanguage(langCode));
    };

    const mobileLangMenu = (currentLanguageCode: string) => (
      <p>
        {
          languages.map((hash, i) => Object.keys(hash).map((l, i2) => {
            const index: string        = `mobileLangMenu_${i}_${i2}`;
            let divider: any           = '';
            const dividerIndex: string = `navMobileLangDivider_${i}_${i2}`;
            const element: string      = languages[i][l] ? languages[i][l] : '';

            if (l === currentLanguageCode) {
              if (i !== (languages.length - 1)) {
                divider = ' | ';
              }

              return <span className="navMobileLangMenuInactive" key={index + currentLanguageCode}>{element + divider}</span>;
            }

            if (i !== (languages.length - 1)) {
              divider = <span className="navMobileLangDivider" key={dividerIndex}> | </span>;
            }

            return (
              <React.Fragment>
                <span className="navMobileLangMenuActive" key={index + currentLanguageCode}>
                  <a onClick={handleChangeLang.bind(this, l)}>{element}</a>
                </span>
                {divider}
              </React.Fragment>
            );
          }))
        }
      </p>
    );

    const langMenu = (currentLanguageCode: string) => (
      <div className="navbar-item has-dropdown is-hoverable">
        <a href="#" className="navbar-link univia-pro-font">
          Language
        </a>
        <div className="navbar-dropdown" id="navPCLangMenu">
          {
            languages.map((hash, i) => Object.keys(hash).map((l) => {
              if (l !== currentLanguageCode) {
                return <a className="navbar-item" key={`navMenu_${i}`} onClick={handleChangeLang.bind(this, l)}>{languages[i][l]}</a>;
              }

              return null;
            }))
          }
        </div>
      </div>
    );

    // language menu enabled
    return (
      <nav className="navbar navigation is-transparent is-black navPCLink">
        <div className="navbar-brand navButton">
          <a className="navbar-item logo" href="https://jin-artist.com">
            <img src="/images/jin_logo.png" alt="JIN" height="28" />
          </a>
          <a role="button" className={`navbar-burger ${globalMenu}`} data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={this.handleMenu}>
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div id="navMenu" className={`navbar-menu ${globalMenu}`}>
          <div className="navbar-start">
            {menus.map((menu, index) => menuItem({ menu, index, }))}
          </div>
          <div className="navbar-end level is-hidden-mobile snsIcon navPCLink" style={{ height: '100%' }}>
            <React.Fragment>
              {langMenu(ui.language)}
              {snsIcons('2x', 'pcIcon')}
            </React.Fragment>
          </div>
          <div className="is-hidden-tablet">
            <div className="" style={{ height: '30px' }} />
            <div className="navbar-item has-text-centered is-size6 univia-pro-font">{mobileLangMenu(ui.language)}</div>
            <div className="" style={{ height: '30px' }} />
          </div>
          <div className="navbar-end level is-mobile is-hidden-tablet navMobileLink" style={{ height: ui.navHeight }}>
            {snsIcons('lg', 'mobileIcon')}
          </div>
        </div>
      </nav>
    );

    // return (
    //   <nav className="navbar navigation is-transparent is-black navPCLink">
    //     <div className="navbar-brand navButton">
    //       <a className="navbar-item logo" href="https://jin-artist.com">
    //         <img src="/images/jin_logo.png" alt="JIN" height="28" />
    //       </a>
    //       <a role="button" className={`navbar-burger ${globalMenu}`} data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={this.handleMenu}>
    //         <span aria-hidden="true" />
    //         <span aria-hidden="true" />
    //         <span aria-hidden="true" />
    //       </a>
    //     </div>

    //     <div id="navMenu" className={`navbar-menu ${globalMenu}`}>
    //       <div className="navbar-start">
    //         {menus.map((menu, index) => menuItem({ menu, index, }))}
    //       </div>
    //       <div className="navbar-end level is-hidden-mobile snsIcon navPCLink" style={{ height: '100%' }}>
    //         <React.Fragment>
    //           {snsIcons('2x', 'pcIcon')}
    //         </React.Fragment>
    //       </div>
    //       <div className="is-hidden-tablet">
    //         <div className="" style={{ height: '30px' }} />
    //       </div>
    //       <div className="navbar-end level is-mobile is-hidden-tablet navMobileLink" style={{ height: ui.navHeight }}>
    //         {snsIcons('lg', 'mobileIcon')}
    //       </div>
    //     </div>
    //   </nav>
    // );
  }
}

export default connect(
  state => ({
    ui: state.ui.data
  })
)(Navigation);
