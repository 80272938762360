/* @flow */
import { combineReducers } from 'redux';

export const initialConfigState: typeConfigState = {
  env:                 'development',
  google_analytics_id: '',
  mode:                'development',
};

export function data(state: typeConfigState = initialConfigState) {
  return state;
}

const config: any = combineReducers({ data });

export default config;
