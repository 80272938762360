/* @flow */
import React from 'react';

const handleClick = (ga: any, label: string) => {
  if (!ga) {
    return;
  }

  const gaEvent: typeGaEventProps = {
    category: 'sns_icon',
    action:   'click',
    label,
  };

  ga.event(gaEvent);
};

const SNSIcon = ({
  link,
  type,
  size,
  cssClass,
  ga
}: typeSnsIconProps) => (
  <div className={`level-item has-text-centered faIcon ${cssClass || ''}`}>
    <div>
      <p>
        <a href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick.bind(this, ga, type)}><i className={`fab fa-${size} fa-${type} faIcon`} /></a>
      </p>
    </div>
  </div>

);

export default SNSIcon;
