/* @flow */
import { combineReducers } from 'redux';

import config, { initialConfigState } from './config';
import ui, { initialUiData }  from './ui';
import notification, { initNotificationData } from '../../../vendor/gitlab.com/taiyuf/react-notification/src/reducers/notification';
// import gotoTop, { initialGotoTopButtunState } from './gotoTop';
import gotoTop, { initialGotoTopButtunState } from '../../../vendor/gitlab.com/taiyuf/react-top-button/src/reducers/gotoTop';

export const initialState = {
  ui: {
    data: initialUiData,
  },
  config: {
    data: initialConfigState,
  },
  notification: {
    data: initNotificationData,
  },
  gotoTop: {
    data: initialGotoTopButtunState,
  },
};

const rootReducer: any = combineReducers({
  ui,
  config,
  notification,
  gotoTop,
});

export default rootReducer;
