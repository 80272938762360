/* @flow */

import { combineReducers } from 'redux';
import _ from 'lodash';

import {
  ENABLE_GLOBAL_MENU,
  DISABLE_GLOBAL_MENU,
  SET_WINDOW_WIDTH,
  SET_NAV_HEIGHT,
  SET_BG_HEIGHT,
  LANG_CODE_JAPANESE,
  SET_LANGUAGE,
} from '../Constants';

export const initialUiData: typeUiState = {
  globalMenu:       false,
  windowHeight:     0,
  navHeight:        0,
  bgHeight:         0,
  language:         LANG_CODE_JAPANESE,
};

export function data(state: typeUiState = initialUiData, action: any) {
  switch (action.type) {
    case ENABLE_GLOBAL_MENU:
      return _.merge({}, state, {
        globalMenu: true,
      });

    case DISABLE_GLOBAL_MENU:
      return _.merge({}, state, {
        globalMenu: false,
      });

    case SET_WINDOW_WIDTH:
      return _.merge({}, state, {
        windowWidth: action.windowWidth,
      });

    case SET_NAV_HEIGHT:
      return _.merge({}, state, {
        navHeight: action.navHeight,
      });

    case SET_BG_HEIGHT:
      return _.merge({}, state, {
        bgHeight: action.bgHeight,
      });

    case SET_LANGUAGE:
      return _.merge({}, state, {
        language: action.language,
      });

    default:
      return state;
  }
}

const ui = combineReducers({ data });

export default ui;
