/* @flow */
import React from 'react';
import PropTypes from 'prop-types';

import StackedNotification from './stackedNotification';

function defaultBarStyleFactory(index: number, style: any) {
  return Object.assign(
    {},
    style,
    {
      // left:       'auto',
      // right:      '-100%',
      color:      '#000',
      background: 'rgba(255, 255, 255, 0.75)',
      bottom:     `${2 + (index * 4)}rem`,
      zIndex:     '10',
    },
  );
}

function defaultActionStyleFactory(index: number, style: any) {
  return Object.assign(
    {},
    style,
    {
      // left:  'auto',
      // right: '1rem'
    },
  );
}


/**
* The notification list does not have any state, so use a
* pure function here. It just needs to return the stacked array
* of notification components.
*/
const NotificationStack = (props: any) => {
  const {
    notifications,
    dismissInOrder,
    barStyleFactory,
    actionStyleFactory,
    activeBarStyleFactory,
    onClick,
    onDismiss,
    dismissAfter,
    action,
  } = props;


  return (
    <div className="notification-list">
      {notifications.map((notification, index) => {
        const isLast = index === 0 && notifications.length === 1;
        const dismissNow = isLast || !dismissInOrder;

        // Handle styles
        const barStyle = barStyleFactory(index, notification.barStyle, notification);
        const actionStyle = actionStyleFactory(index, notification.actionStyle, notification);
        const activeBarStyle = activeBarStyleFactory(
          index,
          notification.activeBarStyle,
          notification
        );

        // Allow onClick from notification stack or individual notifications
        const _onClick = (notification.onClick || onClick).bind(this, notification);
        const _onDismiss = onDismiss.bind(this, notification);

        let _dismissAfter = notification.dismissAfter;

        if (_dismissAfter !== false) {
          if (_dismissAfter == null) {
            _dismissAfter = dismissAfter;
          }
          if (!dismissNow) {
            _dismissAfter += index * 1000;
          }
        }

        return (
          <StackedNotification
            {...notification}
            key={notification.key}
            isLast={isLast}
            action={notification.action || action}
            dismissAfter={dismissAfter}
            onDismiss={_onDismiss}
            onClick={_onClick}
            activeBarStyle={activeBarStyle}
            barStyle={barStyle}
            actionStyle={actionStyle}
            />
        );
      })}
    </div>
  );
};

NotificationStack.propTypes = {
  activeBarStyleFactory: PropTypes.func,
  barStyleFactory:       PropTypes.func,
  actionStyleFactory:    PropTypes.func,
  dismissInOrder:        PropTypes.bool,
  notifications:         PropTypes.array.isRequired,
  onDismiss:             PropTypes.func.isRequired,
  onClick:               PropTypes.func,
  action:                PropTypes.string,
  dismissAfter:          PropTypes.number,
};

NotificationStack.defaultProps = {
  activeBarStyleFactory: defaultBarStyleFactory,
  barStyleFactory:       defaultBarStyleFactory,
  actionStyleFactory:    defaultActionStyleFactory,
  dismissInOrder:        true,
  dismissAfter:          1000,
  onClick:               () => {},
  action:                '',
};

export default NotificationStack;
