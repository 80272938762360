import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

export class GoogleAnalytics extends React.Component {
  static propTypes = {
    ga: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.ga = props.ga;
    this.logPageChange = this.logPageChange.bind(this);
  }

  componentDidMount() {
    if (!this.ga.active) {
      return;
    }

    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    if (!this.ga.active) {
      return;
    }

    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    if (!this.ga.active) {
      return;
    }

    const page = pathname + search;
    const { location } = window;

    this.ga.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });

    this.ga.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search:   PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

GoogleAnalytics.defaultProps = {
  options: {}
};

export const RouteTracker = ({ Component, ...rest }) =>
  <Route {...rest} render={props => (<Component {...props} ga={rest.ga} />)} />;

RouteTracker.propTypes = {
  Component: PropTypes.func.isRequired,
  rest:      PropTypes.object,
};

RouteTracker.defaultProps = {
  rest: {},
};

class Ga {
  constructor(props) {
    this.googleAnalyticsID = props.googleAnalyticsID;

    let debug;
    if (props.env) {
      debug = props.env === 'development';
    } else {
      debug = false;
    }

    const gaOptions = {
      debug,
      ...props,
    };

    ReactGA.initialize(this.googleAnalyticsID, gaOptions);

    this.GA = ReactGA;
    this.plugin = {};

    this.set            = this.set.bind(this);
    this.pageview       = this.pageview.bind(this);
    this.modalview      = this.modalview.bind(this);
    this.event          = this.event.bind(this);
    this.timing         = this.timing.bind(this);
    this.ga             = this.ga.bind(this);
    this.outboundLink   = this.outboundLink.bind(this);
    this.exception      = this.exception.bind(this);
    this.plugin.require = this.pluginRequire.bind(this);
    this.plugin.execute = this.pluginExecute.bind(this);
    this.active         = this.active.bind(this);
  }

  active() {
    if (typeof document === 'undefined') {
      return false;
    }

    if (!this.google_analytics_id) {
      return false;
    }

    if (!this.GA) {
      return false;
    }

    return true;
  }

  set(props) {
    if (!this.active) {
      return;
    }

    this.GA.set(props);
  }

  pageview(props) {
    if (!this.active) {
      return;
    }

    this.GA.pageview(props);
  }

  modalview(props) {
    if (!this.active) {
      return;
    }

    this.GA.modalview(props);
  }

  event(props) {
    if (!this.active) {
      return;
    }

    this.GA.event(props);
  }

  timing(props) {
    if (!this.active) {
      return;
    }

    this.GA.timing(props);
  }

  ga(props) {
    if (!this.active) {
      return;
    }

    this.GA.ga(props);
  }

  outboundLink(props) {
    if (!this.active) {
      return;
    }

    this.GA.outboundLink(props);
  }

  exception(props) {
    if (!this.active) {
      return;
    }

    this.GA.exception(props);
  }

  pluginRequire(props) {
    if (!this.active) {
      return;
    }

    this.GA.plugin.require(props);
  }

  pluginExecute(props) {
    if (!this.active) {
      return;
    }

    this.GA.plugin.execute(props);
  }
}

export const init = ({ googleAnalyticsID, env }) => new Ga({
  googleAnalyticsID,
  env,
});
