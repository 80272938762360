/* @flow */

import { combineReducers } from 'redux';
import _                   from 'lodash';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from '../Constants';

export const initNotificationData: Array<string> = [];

export function data(state: Array<string> = initNotificationData, action: { type: string, notification: any }): any {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.notification];

    case REMOVE_NOTIFICATION:
      return _.difference(state, [action.notification]);

    default:
      return state;
  }
}

const notification = combineReducers({ data });

export default notification;
