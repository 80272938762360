/* @flow */
import React from 'react';
import { PropTypes } from 'prop-types';

const defaultInterval: number = 250;  // milisecond
const defaultTimeout: number  = 4000; // milisecond

class GotoTop extends React.Component<{ interval?: number; timeout?: number; baseClassName: string; hiddenClassName: string; imagePath: string; height: string; width: string; opacity: string; }, { visible: boolean; position: number; positionTimerIds: Array<TimeoutID>; visibleTimerIds: Array<TimeoutID>; }> {
  static handleGotoTop() {
    if (!window) {
      return;
    }

    window.scrollTo(0, 0);
  }

  static propTypes = {
    interval:        PropTypes.number,
    timeout:         PropTypes.number,
    baseClassName:   PropTypes.string.isRequired,
    hiddenClassName: PropTypes.string.isRequired,
    imagePath:       PropTypes.string.isRequired,
    height:          PropTypes.string,
    width:           PropTypes.string,
    opacity:         PropTypes.string,
  };

  static defaultProps = {
    interval:  defaultInterval,
    timeout:   defaultTimeout,
    height:    '32',
    width:     '32',
    opacity:   '0.6',
  };

  constructor(props: { interval?: number; timeout?: number; baseClassName: string; hiddenClassName: string; imagePath: string; height: string; width: string; opacity: string; }) {
    super(props);

    this.state = {
      visible:          false,
      position:         0,
      positionTimerIds: [],
      visibleTimerIds:  [],
    };

    this.interval        = props.interval || defaultInterval;
    this.timeout         = props.timeout  || defaultTimeout;
    this.baseClassName   = props.baseClassName;
    this.hiddenClassName = props.hiddenClassName;
    this.imagePath       = props.imagePath;
    this.height          = props.height;
    this.width           = props.width;
    this.opacity         = props.opacity || '0.6';
  }

  componentDidMount() {
    if (!window) {
      return;
    }

    let positionTimer;
    let visibleTimer;

    window.addEventListener('scroll', () => {
      const {
        positionTimerIds,
        visibleTimerIds,
        visible,
        position,
      } = this.state;

      const currentPosition: number = window.scrollY;

      // もしすでに同じ処理が走っていた場合、それらをキャンセルする
      if (positionTimerIds.length > 0) {
        positionTimerIds.map(t => clearTimeout(t));
        this.setState({ positionTimerIds: [] });
      }

      if (currentPosition !== 0 && position !== currentPosition) {
        if (visible === false) {
          // まだ表示されていない場合
          this.setState({ visible: true });
        }
      }

      positionTimer = setTimeout(() => {
        if (currentPosition !== 0 && position !== currentPosition) {
          // まだスクロールされている時

          // もしすでに同じ処理が走っていた場合、それらをキャンセルする
          if (visibleTimerIds.length > 0) {
            visibleTimerIds.map(t => clearTimeout(t));
            this.setState({ visibleTimerIds: [] });
          }

          // timeout milisecond 後に表示を消す
          visibleTimer = setTimeout(() => this.setState({ visible: false }), this.timeout);

          if (visibleTimer) {
            this.setState({ visibleTimerIds: [...visibleTimerIds, visibleTimer] });
          }
        }
      }, this.interval);

      this.setState({ position: currentPosition });

      if (positionTimer) {
        this.setState({ positionTimerIds: [...positionTimerIds, positionTimer] });
      }
    });
  }

  interval: number;

  timeout: number;

  baseClassName: string;

  hiddenClassName: string;

  imagePath: string;

  height: string;

  width: string;

  opacity: string;

  render() {
    const { visible }     = this.state;
    const classes: string = `${this.baseClassName}${visible ? '' : this.hiddenClassName}`;
    const divStyle = {
      opacity:  this.opacity,
      zIndex:   30,
      right:    '20px',
      position: 'fixed',
      bottom:   '20px',
      margin:   'auto',
    };
    const aStyle = {
      // color:          '#4a4a4a',
      textDecoration: 'none',
    };

    return (
      <div className={classes} style={divStyle}>
        <p>
          <a href="#" onClick={GotoTop.handleGotoTop} style={aStyle}>
            <img src={this.imagePath} alt="top" width={this.width} height={this.height} />
          </a>
        </p>
      </div>
    );
  }
}

export default GotoTop;
