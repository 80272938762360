export const ENABLE_GLOBAL_MENU = 'enable_global_menu';
export const DISABLE_GLOBAL_MENU = 'disable_global_menu';
export const SET_SCROLL_POSITION = 'set_scroll_position';
export const SET_WINDOW_WIDTH = 'set_window_width';
export const SET_NAV_HEIGHT = 'set_nav_height';
export const SET_BG_HEIGHT = 'set_bg_height';
export const MV_HEMINGWAY_URL = 'https://youtu.be/h2UAW_GWwhA';
export const MV_HEMINGWAY_EMBED_URL = 'https://www.youtube.com/embed/h2UAW_GWwhA';
export const SUBSCRIPTION_HEMINGWAY_APPLE_URL = 'https://itunes.apple.com/jp/album/love-song-that-cannot-be-even-written-down-by-hemingway/1399525770';
export const SUBSCRIPTION_HEMINGWAY_GOOGLE_URL = 'https://play.google.com/store/music/album/JIN_%E3%83%98%E3%83%9F%E3%83%B3%E3%82%B0%E3%82%A6%E3%82%A7%E3%82%A4%E3%81%AB%E3%82%82%E6%9B%B8%E3%81%8D%E5%B0%BD%E3%81%8F%E3%81%9B%E3%81%AA%E3%81%84%E6%84%9B%E3%81%AE%E6%AD%8C?id=B2cskh247jx7sp7x6xrqijiqypq&hl=ja';
export const SUBSCRIPTION_HEMINGWAY_AMAZON_URL = 'https://www.amazon.co.jp/ヘミングウェイにも書き尽くせない愛の歌-JIN/dp/B07DTWQW7B/';
export const SUBSCRIPTION_HEMINGWAY_SPOTIFY_URL = 'https://open.spotify.com/track/6gdhT6Uyo4Ao5iGXeE5JmM?si=2HL5ShT0RLy4H2xV44IUsw';
export const SUBSCRIPTION_HEMINGWAY_LINE_URL = 'https://music.line.me/album/mb0000000001691001';
export const LANG_CODE_JAPANESE = 'JP';
export const LANG_CODE_ENGLISH = 'EN';
export const LANG_CODE_CHINESE = 'CN';
export const LANG_NAME_JAPANESE = 'Japanese';
export const LANG_NAME_ENGLISH = 'English';
export const LANG_NAME_CHINESE = 'Chinese';
export const SET_LANGUAGE = 'set_language';
export const ADD_NOTIFICATION = 'add_notification';
export const REMOVE_NOTIFICATION = 'remove_notification';
export const URL_MUSIC_BB = 'http://www.music-bb.com/';
export const JIN_ARTIST_COM_LANGUAGE = 'jin-artist.com_lang';
