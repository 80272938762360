/* @flow */
import React from 'react';

const Footer = () => (
  <footer>
    <div className="container">
      <div className="content has-text-centered">
        <p className="footer univia-pro-font">Copyright (C) try-m company, LLC. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
