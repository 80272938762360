/* @flow */

import { combineReducers } from 'redux';
import _ from 'lodash';

import {
  SET_SCROLL_POSITION,
  SET_TOP_BUTTON_ENABLE,
  SET_TOP_BUTTON_DISABLE,
  SET_TIMER_ID,
  RESET_TIMER_ID,
} from '../Constants';

// declare type typeGotoTopButtonState = {
//   currentPosition: number;
//   previousPosition: number;
// }

export const initialGotoTopButtunState: { currentPosition: number; previousPosition: number; visible: boolean; timerIds: Array<TimeoutID>; } = {
  currentPosition:  0,
  previousPosition: 0,
  visible:          false,
  timerIds:         [],
};

export function data(state: { currentPosition: number; previousPosition: number; visible: boolean; timerIds: Array<TimeoutID>; } = initialGotoTopButtunState, action: { type: string, position?: number, timerId?: TimeoutID; }) {
  switch (action.type) {
    case SET_SCROLL_POSITION:
      return _.merge({}, state, {
        currentPosition:  action.position,
        previousPosition: state.currentPosition,
      });

    case SET_TOP_BUTTON_ENABLE:
      return _.merge({}, state, {
        visible: true,
      });

    case SET_TOP_BUTTON_DISABLE:
      return _.merge({}, state, {
        visible: false,
      });

    case SET_TIMER_ID:
      return _.merge({}, state, {
        timerId: [...state.timerIds, action.timerId],
      });

    case RESET_TIMER_ID:
      return _.merge({}, state, {
        timerId: [],
      });

    default:
      return state;
  }
}

const gotoTop = combineReducers({ data });

export default gotoTop;
