/* @flow */
import * as React from 'react';
import {
  PropTypes,
  instanceOf,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter,
  Switch,
  Route,
} from 'react-router-dom';
import {
  withCookies,
  Cookies,
} from 'react-cookie';

import Navigation from './Navigation';
import Footer from '../components/Footer';
import GotoTop from '../../../vendor/gitlab.com/taiyuf/react-top-button/src/GotoTop';
import * as GA from '../../../vendor/gitlab.com/taiyuf/react-google-analytics/GoogleAnalytics';
import NotificationStack from '../../../vendor/gitlab.com/taiyuf/react-notification/src/containers/notificationStack';
import {
  addNotification,
  removeNotification,
} from '../../../vendor/gitlab.com/taiyuf/react-notification/src/actions/notification';

import {
  Top,
} from '../lazy';

const routeArray: Array<typeRoute> = [
  // {
  //   path:      '/',
  //   component: Top
  // },
];

export const routes: any = props => (
  <React.Fragment>
    <Route exact path="/" render={ps => <Top {...Object.assign({}, props, ps)} />} />
    {routeArray.map((route, index) => <Route path={route.path} render={ps => <route.component {...Object.assign({}, props, ps)} />} key={`route_${index}`} />)}
  </React.Fragment>
);


const defaultBarStyle = (index: number, style: any) => Object.assign({}, style, {
  // justifyContent: 'flex-end',
  // color:      '#000',
  // background: 'rgba(255, 255, 255, 0.5)',
  // bottom:     `${1 + (index * 4)}rem`,
  // zIndex:     '10',
});

const defaultActiveBarStyle = (index: number, style: any) => Object.assign({}, style, {
});

class App extends React.Component<typeAppProps> {
  static propTypes = {
    config:               PropTypes.object.isRequired,
    ui:                   PropTypes.object.isRequired,
    dispatch:             PropTypes.func.isRequired,
    ga:                   PropTypes.object,
    initialNotifications: PropTypes.array,
    notifications:        PropTypes.array,
    cookies:              instanceOf(Cookies).isRequired,
  };

  static defaultProps = {
    ga:                   null,
    initialNotifications: [],
    notifications:        null,
  };

  componentDidMount() {
    const {
      initialNotifications,
      dispatch,
    } = this.props;

    if (initialNotifications.length > 0) {
      initialNotifications.map(message => dispatch(addNotification(message, dispatch)));
    }
  }

  componentDidCatch(error, info) {
    console.dir('error:');
    console.dir(error);
    console.dir('info');
    console.dir(info);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { auth, dispatch, socket } = nextProps;
  //   const data: typeLocation = {
  //     chatID:    auth.customerInfo && auth.customerInfo.chat_id,
  //     email:     auth.customerInfo && auth.customerInfo.email,
  //     modelName: auth.customerInfo && auth.customerInfo.model_name
  //   };
  //   socket.emit(SET_LOCATION, data);
  // }

  render(): React.Node {
    const {
      ga,
      dispatch,
      notifications,
    } = this.props;

    const isServerRendering: boolean = typeof document === 'undefined';
    const gotoTopImagePath: string   = '/images/top-button.png';
    const gotoTopSize: string        = '48';

    const renderNotification = (ns) => {
      if (ns.length === 0) {
        return null;
      }

      return (
        <NotificationStack
          barStyleFactory={defaultBarStyle}
          actionStyleFactory={defaultActiveBarStyle}
          notifications={ns}
          dismissAfter={5000}
          onDismiss={n => dispatch(removeNotification(n))}
          />
      );
    };

    return (
      <div style={{ height: '100%' }} id="top">
        { renderNotification(notifications) }
        <Navigation {...this.props} />
        { !isServerRendering && ga && <GA.RouteTracker Component={GA.GoogleAnalytics} {...this.props} /> }
        <Switch>
          { routes(this.props) }
        </Switch>
        <section>
          <figure className="media-left">
            <GotoTop
              baseClassName="has-text-right univia-pro-font gotoTop "
              hiddenClassName="is-invisible"
              imagePath={gotoTopImagePath}
              height={gotoTopSize}
              width={gotoTopSize}
              {...this.props}
            />
          </figure>
        </section>
        <Footer />
      </div>
    );
  }
}

export default withCookies(withRouter(connect(state => ({
  ui:            state.ui.data,
  config:        state.config.data,
  notifications: state.notification.data,
}))(App)));
