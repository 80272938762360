import Loadable from 'react-loadable';

import Loading from './components/Loading';

export const Top = Loadable({
  loader:  () => import(/* webpackChunkName: "Top" */ './components/Top'),
  loading: Loading,
});

// export const Information = Loadable({
//   loader:  () => import(/* webpackChunkName: "Information" */ './containers/Information'),
//   loading: Loading,
// });

// export const Discography = Loadable({
//   loader:  () => import(/* webpackChunkName: "Discography" */ './components/Discography'),
//   loading: Loading,
// });

// export const Profile = Loadable({
//   loader:  () => import(/* webpackChunkName: "Profile" */ './components/Profile'),
//   loading: Loading,
// });
